import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import "../../components/animations/scroll.scss"

import fbRecommendations from "../../images/fb-recommendations.svg"
import Stars from "../../components/SocialReviews/Stars"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

function RatingsAndReviews(props) {
  if (props.topDown) {
    return (
      <section className="home-section ratings-review top-down">
        <div className="container" data-aos="fade-up" data-aos-duration="1200">
          <div className="columns">
            <div className="column is-2"></div>
            <div className="column is-20">
              {props.iconless && (
                <h2
                  className="has-text-centered-tablet h3-style"
                  style={{ marginBottom: "0" }}
                >
                  {props.post.homeRatingsReviews.heading}
                </h2>
              )}
              {!props.iconless && (
                <h2 className="has-text-centered-tablet h3-style">
                  {props.post.homeRatingsReviews.heading}
                </h2>
              )}
              {/* <div
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(props.post.homeRatingsReviews.text)
                )}
              ></div> */}
            </div>
            <div className="column is-2"></div>
          </div>
          <div className="columns">
            <div className="column"></div>
            <div className="column is-20-tablet is-14-desktop">
              {!props.iconless && (
                <WithIcons
                  language={props.language}
                  post={props.post}
                  google={props.google}
                  facebook={props.facebook}
                />
              )}
              {props.iconless && (
                <IconlessControl
                  language={props.language}
                  type="big-rating"
                  post={props.post}
                  google={props.google}
                  facebook={props.facebook}
                  east={props.east}
                  coastal={props.coastal}
                />
              )}
            </div>
            <div className="column"></div>
          </div>
        </div>
      </section>
    )
  }
  return (
    <section
      className="home-section ratings-review left-right"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="columns is-desktop">
        <div className="column is-2"></div>
        <div className="column is-10">
          {props.iconless && <h2>{props.post.homeRatingsReviews.heading}</h2>}
          {!props.iconless && <h3>{props.post.homeRatingsReviews.heading}</h3>}
          <div
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.homeRatingsReviews.text)
            )}
          ></div>
        </div>
        <div className="column is-2"></div>
        <div className="column is-8">
          {!props.iconless && (
            <WithIcons
              noButton
              post={props.post}
              google={props.google}
              facebook={props.facebook}
            />
          )}
          {props.iconless && (
            <IconlessControl
              type="big-rating"
              noButton
              post={props.post}
              google={props.google}
              facebook={props.facebook}
            />
          )}
        </div>
        <div className="column is-2"></div>
      </div>
    </section>
  )
}

function IconlessControl(props) {
  if (props.type === "big-rating") {
    return (
      <BigRating
        language={props.language}
        google={props.google}
        facebook={props.facebook}
        post={props.post}
        east={props.east}
        coastal={props.coastal}
      />
    )
  }
  return (
    <>
      <div
        className="rating-reviews home-reviews columns has-text-centered iconless inline-stars"
        style={{ paddingTop: "40px" }}
      >
        <div className="column social-column">
          <div className="featured-social">
            <a
              className="link-wrapper"
              href={props.google.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>Google</h3>
              <div className="review-icons">
                <Stars rating={props.google.rating} />
              </div>
            </a>
            <a
              className="link-wrapper"
              href={props.google.url}
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          </div>
          <a href={props.google.url} className="reviews-link" target="_blank">
            <span className="reviews-number">{props.google.totalReviews}</span>{" "}
            <span className="reviews-word">
              {props.language === "es" ? "Reseñas" : "Reviews"}
            </span>
          </a>
        </div>
        <div className="column social-column">
          <div className="featured-social">
            <a
              className="link-wrapper"
              href={props.facebook.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>Facebook</h3>
              <div className="review-icons">
                <Stars rating={props.facebook.rating} />
              </div>
            </a>
            <a
              className="link-wrapper"
              href={props.facebook.url}
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          </div>
          <a href={props.facebook.url} className="reviews-link" target="_blank">
            <span className="reviews-number">
              {props.facebook.totalReviews}
            </span>{" "}
            <span className="reviews-word">
              {props.language === "es" ? "Reseñas" : "Recommendations"}
            </span>
          </a>
        </div>
      </div>

      <div className="columns has-text-centered">
        <div className="column">
          <Button
            contained
            buttonText={props.post.homeRatingsReviews.buttonOne.buttonText}
            href={props.post.homeRatingsReviews.buttonOne.href}
          />
        </div>
      </div>
    </>
  )
  return (
    <>
      <div
        className="rating-reviews home-reviews columns has-text-centered iconless"
        style={{ paddingTop: "40px" }}
      >
        <div className="column">
          <div className="featured-social">
            <a
              className="link-wrapper"
              href={props.google.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>Google</h3>
            </a>
            <a
              className="link-wrapper"
              href={props.google.url}
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a href={props.google.url} className="reviews-link" target="_blank">
              {props.google.totalReviews}{" "}
              {props.language === "es" ? "Reseñas" : "Reviews"}
            </a>
            <div className="review-icons">
              <h4 className="google-rating">
                {props.google.rating.toFixed(1)}
              </h4>
              <Stars rating={props.google.rating} />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="featured-social">
            <a
              className="link-wrapper"
              href={props.facebook.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>Facebook</h3>
            </a>
            <a
              className="link-wrapper"
              href={props.facebook.url}
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href={props.facebook.url}
              className="reviews-link"
              target="_blank"
            >
              {props.facebook.totalReviews}{" "}
              {props.language === "es" ? "Reseñas" : "Reviews"}
            </a>
          </div>
        </div>
      </div>

      <div className="columns has-text-centered">
        <div className="column">
          <Button
            contained
            buttonText={props.post.homeRatingsReviews.buttonOne.buttonText}
            href={props.post.homeRatingsReviews.buttonOne.href}
          />
        </div>
      </div>
    </>
  )
}

export function BigRating(props) {
  let eastGoogle = props.east.data
  let coastalGoogle = props.coastal.data

  return (
    <>
      <Ratings
        language={props.language}
        facebook={props.facebook}
        eastGoogle={eastGoogle}
        coastalGoogle={coastalGoogle}
      />
      <div className="columns has-text-centered">
        <div className="column">
          <Button
            contained
            buttonText={props.post.homeRatingsReviews.buttonOne.buttonText}
            href={props.post.homeRatingsReviews.buttonOne.href}
          />
        </div>
      </div>
    </>
  )
}

export function Ratings(props) {
  return (
    <div
      className="rating-reviews home-reviews columns is-centered iconless big-rating"
      style={{ paddingTop: "1.5rem" }}
    >
      <div className="column social-column is-narrow bottom-col-mobile">
        <div className="featured-social">
          <div>
            <span
              style={{
                fontSize: "0.875rem",
                lineHeight: "1.71",
                color: "#6d6e6f",
                display: "block",
                marginBottom: "-4px",
                fontWeight: "bold",
                fontFamily: "Montserrat"
              }}
            >
              {props.language === "es"
                ? "Oficina de la Costa"
                : "Coastal Office"}
            </span>
            <a
              className="link-wrapper"
              href={props.coastalGoogle.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>Google</h3>
              <div className="total-reviews">
                ({props.coastalGoogle.totalReviews})
              </div>
            </a>
          </div>
          <div>
            <a
              href={props.coastalGoogle.url}
              className="reviews-link"
              target="_blank"
            >
              <div className="review-icons">
                <Stars rating={props.coastalGoogle.rating} />
              </div>
            </a>
            <a
              href={props.coastalGoogle.url}
              className="reviews-link"
              target="_blank"
            >
              <span className="reviews-number">
                {props.coastalGoogle.rating === 5
                  ? "5.0"
                  : props.coastalGoogle.rating}
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="column is-2"></div>
      <div className="column social-column is-narrow">
        <div className="featured-social">
          <div>
            <span
              style={{
                fontSize: "0.875rem",
                lineHeight: "1.71",
                color: "#6d6e6f",
                display: "block",
                marginBottom: "-4px",
                fontWeight: "bold",
                fontFamily: "Montserrat"
              }}
            >
              {props.language === "es" ? "Oficina del Este" : "East Office"}
            </span>
            <a
              className="link-wrapper"
              href={props.eastGoogle.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>Google</h3>
              <div className="total-reviews">
                ({props.eastGoogle.totalReviews})
              </div>
            </a>
          </div>
          <div>
            <a
              href={props.eastGoogle.url}
              className="reviews-link"
              target="_blank"
            >
              <div className="review-icons">
                <Stars rating={props.eastGoogle.rating} />
              </div>
            </a>
            <a
              href={props.eastGoogle.url}
              className="reviews-link"
              target="_blank"
            >
              <span className="reviews-number">
                {props.eastGoogle.rating === 5
                  ? "5.0"
                  : props.eastGoogle.rating}
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="column is-2"></div>
      <div className="column social-column last is-narrow">
        <div className="featured-social">
          <div>
            <a
              className="link-wrapper"
              href={props.facebook.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>Facebook</h3>
              <div className="total-reviews">
                ({props.facebook.totalReviews})
              </div>
            </a>
          </div>
          <div>
            <a
              href={props.facebook.url}
              className="reviews-link"
              target="_blank"
            >
              <div className="review-icons">
                <Stars rating={props.facebook.rating} />
              </div>
            </a>
            <a
              href={props.facebook.url}
              className="reviews-link"
              target="_blank"
            >
              <span className="reviews-number">
                {props.facebook.rating === 5 ? "5.0" : props.facebook.rating}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

function WithIcons(props) {
  return (
    <>
      <div className="rating-reviews home-reviews">
        <div className="featured-social">
          <a
            className="link-wrapper"
            href={props.google.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>Google</h3>
          </a>
          <a
            className="link-wrapper"
            href={props.google.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="review-icons">
              <h4 className="google-rating">
                {props.google.rating.toFixed(1)}
              </h4>
              <Stars rating={props.google.rating} />
            </div>
          </a>
          <a href={props.google.url} target="_blank">
            {props.google.totalReviews}{" "}
            {props.language === "es" ? "Reseñas" : "Reviews"}
          </a>
        </div>
        <div className="featured-social">
          <a
            className="link-wrapper"
            href={props.facebook.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>Facebook</h3>
          </a>
          <a
            className="link-wrapper"
            href={props.facebook.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="review-icons">
              <div className="svg-wrapper">
                <img src={fbRecommendations} alt="Facebook Recommendations" />
              </div>
            </div>
          </a>
          <a href={props.facebook.url} target="_blank">
            {props.facebook.totalReviews}{" "}
            {props.language === "es" ? "Reseñas" : "Reviews"}
          </a>
        </div>
      </div>

      {!props.noButton && (
        <div className="columns has-text-centered">
          <div className="column">
            <Button
              contained
              buttonText={props.post.homeRatingsReviews.buttonOne.buttonText}
              href={props.post.homeRatingsReviews.buttonOne.href}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default RatingsAndReviews
